<template>
    <CContainer>
        <CCard>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submit)">
                    <CCardHeader>
                        <h4 class="m-0">{{ $t('pages.content.accountManagement.headers.create') }}</h4>
                    </CCardHeader>
                    <CCardBody>
                        <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.name', { language: $t('generally.languages.de') })"
                                :rules="{ required: true, min: 3, max: 32 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.name', { language: $t('generally.languages.de') })"
                                >
                                    <b-form-input
                                        v-model="form.name.de"
                                        maxlength="32"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="name-feedback-de"
                                    />
                                    <b-form-invalid-feedback id="name-feedback-de">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.name', { language: $t('generally.languages.en') })"
                                :rules="{ required: true, min: 3, max: 32 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.name', { language: $t('generally.languages.en') })"
                                >
                                    <b-form-input
                                        v-model="form.name.en"
                                        maxlength="32"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="name-feedback-en"
                                    />
                                    <b-form-invalid-feedback id="name-feedback-en">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.description', { language: $t('generally.languages.de') })"
                                :rules="{ required: true, max: 2048 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.description', { language: $t('generally.languages.de') })"
                                >
                                    <b-form-textarea
                                        v-model="form.description.de"
                                        maxlength="2048"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="description-feedback-de"
                                    />
                                    <b-form-invalid-feedback id="description-feedback-de">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.description', { language: $t('generally.languages.en') })"
                                :rules="{ required: true, max: 2048 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.description', { language: $t('generally.languages.en') })"
                                >
                                    <b-form-textarea
                                        v-model="form.description.en"
                                        maxlength="2048"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="description-feedback-en"
                                    />
                                    <b-form-invalid-feedback id="description-feedback-en">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.vdi.apps.form.image').toString()"
                                :rules="{ required: false, max: 255 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                >
                                    <template #label>
                                        {{ $t('pages.content.accountManagement.form.image') }}
                                        <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.content.accountManagement.form.hints.image')"/>
                                    </template>
                                    <b-input-group>
                                        <b-input-group-prepend>
                                            <b-input-group-text class="bg-white">
                                                <img :src="form.image" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                                                <font-awesome-icon :icon="['fas', 'user-lock']" v-show="!picture" class="text-orange"/>
                                            </b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            type="url"
                                            name="image"
                                            maxlength="255"
                                            :state="getValidationState(validationContext)"
                                            v-model="form.image"
                                            :placeholder="$t('pages.vdi.apps.form.image.placeholder')"
                                            aria-describedby="image-feedback"
                                        />
                                        <b-form-invalid-feedback id="image-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.accountManagement.form.instancesExpireAt')"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.accountManagement.form.instancesExpireAt')"
                                >
                                    <b-form-datepicker
                                        v-model="form.expireDate"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="expire-feedback"
                                        hide-header
                                        v-bind="$t('modules.datepicker') || {}"
                                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                        :min="new Date()"
                                    ></b-form-datepicker>
                                    <b-form-invalid-feedback id="expire-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>

                                </b-form-group>
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3">
                                    <b-form-timepicker
                                        v-model="form.expireTime"
                                        locale="de"
                                        no-close-button
                                        hide-header
                                        aria-describedby="expire-feedback"
                                        :state="getValidationState(validationContext)"
                                        v-bind="$t('modules.timepicker') || {}"
                                    ></b-form-timepicker>
                                    <b-form-invalid-feedback id="expire-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <b-form-group
                                label-cols-sm="12"
                                label-cols-md="3"
                            >
                                <template #label>
                                    {{ $t('pages.content.accountManagement.form.public') }}
                                    <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.content.accountManagement.form.hints.public')"/>
                                </template>
                                <b-form-select v-model="form.public">
                                    <b-form-select-option :value="false">{{ $t('pages.content.accountManagement.values.public.no') }}</b-form-select-option>
                                    <b-form-select-option :value="true">{{ $t('pages.content.accountManagement.values.public.yes') }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group>
                                <template #label>
                                    {{ $t('pages.content.accountManagement.form.instanceInfoTemplate') }} (<CLink @click="editorToggle = !editorToggle"><font-awesome-icon icon="code"/></CLink>)
                                </template>
                                <vue-editor
                                    v-if="editorToggle"
                                    v-model="form.instanceInfoTemplate"
                                ></vue-editor>
                                <vue-codeditor style="font-size: 14px" v-else v-model="form.instanceInfoTemplate" mode="html" theme="monokai"/>
                            </b-form-group>
                            <b-form-group
                                class="text-right"
                            >
                                <b-button variant="orange" size="sm" v-b-modal.accounts-new-instances-modal>
                                    <font-awesome-icon icon="plus"/>
                                    {{ $t('pages.content.accountManagement.form.instances.button') }}
                                </b-button>
                            </b-form-group>
                            <b-form-group>
                                <b-table
                                    :items="form.instances"
                                    :fields="fields"
                                    :empty-text="$t('pages.content.accountManagement.form.instances.table.empty')"
                                    show-empty
                                    small
                                    striped
                                >
                                    <template #cell(infoValues)="row">
                                        {{ row.item.infoValues }}
                                    </template>
                                    <template #cell(actions)="row">
                                        <b-link v-b-modal.preview-json-modal @click="json = JSON.parse(row.item.infoValues)">
                                            <font-awesome-icon icon="code"/>
                                        </b-link>
                                        <b-link @click="removeAccount(row)" class="mx-2 text-danger">
                                            <font-awesome-icon icon="trash-alt"/>
                                        </b-link>
                                    </template>
                                </b-table>
                            </b-form-group>
                        </b-overlay>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-end">
                        <b-button type="submit" variant="orange">{{ $t("buttons.create") }}</b-button>
                    </CCardFooter>
                </b-form>
            </validation-observer>
        </CCard>
        <b-modal
            id="accounts-new-instances-modal"
            :ok-title="$t('buttons.create')"
            ok-variant="orange"
            @ok="addAccounts"
            :cancel-title="$t('buttons.cancel')"
            :title="$t('pages.content.accountManagement.form.instances.modal.title')"
        >
            <h4>{{ $t('pages.content.accountManagement.form.instances.modal.excelHeader') }}</h4>
            <b-form-group>
                <template #label>
                    {{ $t('pages.content.accountManagement.form.instances.modal.excel') }} <br>
                    <small v-html="$t('pages.content.accountManagement.form.instances.modal.excelDescription')"></small>
                </template>
                <b-form-textarea v-model="account.excel"></b-form-textarea>
            </b-form-group>
            <hr>
            <h4>{{ $t('pages.content.accountManagement.form.instances.modal.singleHeader') }}</h4>
            <b-form-group
                :label="$t('pages.content.accountManagement.form.instances.table.name')"
            >
                <b-input v-model="account.name"></b-input>
            </b-form-group>
            <b-form-group
                :label="$t('pages.content.accountManagement.form.instances.table.secret')"
            >
                <b-input v-model="account.secret"></b-input>
            </b-form-group>
            <b-form-group
                :label="$t('pages.content.accountManagement.form.instances.table.infoValues')"
            >
                <vue-codeditor style="font-size: 14px" v-model="account.infoValues" mode="json" theme="monokai"/>
            </b-form-group>
        </b-modal>
        <b-modal id="preview-json-modal" title="JSON" size="lg" ok-only>
            <vue-json-pretty :path="'res'" :data="json"></vue-json-pretty>
        </b-modal>
    </CContainer>
</template>

<script>
import accountService from "../../../services/accountService";
import VueJsonPretty from "vue-json-pretty";
import 'vue-json-pretty/lib/styles.css';
import {VueEditor} from "vue2-editor";

export default {
    name: "NewAccount",
    components: {
        VueJsonPretty,
        VueEditor
    },
    data: () => ({
        fields: [],
        form: {
            name: {
                de: '',
                en: ''
            },
            description: {
                de: '',
                en: ''
            },
            instances: [],
            image: '',
            public: false,
            expireTime: '',
            expireDate: '',
            instanceInfoTemplate: ''
        },
        account: {},
        picture: false,
        loading: false,
        json: '',
        editorToggle: false,
    }),
    mounted() {
        this.fields = [
            {key: 'name', label: this.$t('pages.content.accountManagement.form.instances.table.name')},
            {key: 'secret', label: this.$t('pages.content.accountManagement.form.instances.table.secret')},
            {key: 'infoValues', label: this.$t('pages.content.accountManagement.form.instances.table.infoValues'), tdClass: 'table-text-wrap'},
            {key: 'actions', label: '', tdClass: 'text-right'}
        ];
        this.initAccountForm();
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        initAccountForm() {
            this.account = {
                excel: '',
                name: '',
                secret: '',
                infoValues: ''
            };
        },
        addAccounts(bvModalEvt) {
            bvModalEvt.preventDefault();
            if ((this.account.excel === '' && this.account.name !== '' && this.account.infoValues !== '' && this.account.secret !== '') || (this.account.excel !== '' && this.account.name === '' && this.account.infoValues === '' && this.account.secret === '')) {
                if (this.account.excel !== '') {
                    let data = [];
                    const lines = this.account.excel.split("\n");
                    for (const linesKey of lines) {
                        if (linesKey === '') break;
                        let row = linesKey.split("\t");
                        let jsonParse = false;
                        try {
                            JSON.parse(row[2]);
                            jsonParse = true;
                        } catch (e) {
                            console.log(e);
                        }
                        if (jsonParse) {
                            if (row.length === 3) {
                                data.push({
                                    name: row[0],
                                    secret: row[1],
                                    infoValues: JSON.stringify(JSON.parse(row[2]))
                                });
                            } else {
                                this.$toastr.e(this.$t('pages.content.accountManagement.messages.error.badTableFormat'));
                                return;
                            }
                        } else {
                            this.$toastr.e(this.$t('pages.content.accountManagement.messages.error.invalidJSON'));
                            return;
                        }
                        this.form.instances = data;

                        this.$nextTick(() => {
                            this.$bvModal.hide('accounts-new-instances-modal');
                        });
                    }
                } else {
                    let jsonParse = false;
                    try {
                        JSON.parse(this.account.infoValues);
                        jsonParse = true;
                    } catch (e) {
                        console.log(e);
                    }
                    if (jsonParse) {
                        this.form.instances.push({
                            name: this.account.name,
                            secret: this.account.secret,
                            infoValues: JSON.stringify(JSON.parse(this.account.infoValues))
                        });
                        this.$nextTick(() => {
                            this.$bvModal.hide('accounts-new-instances-modal');
                        });
                    } else {
                        this.$toastr.e(this.$t('pages.content.accountManagement.messages.error.invalidJSON'));
                        return;
                    }
                }
                this.initAccountForm();
            } else {
                this.$toastr.e(this.$t('pages.content.accountManagement.messages.error.addAccount'));
            }
        },
        removeAccount(item) {
            this.form.instances.splice(item.index, 1);
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                let data = Object.assign({}, this.form);
                data.name = JSON.stringify(data.name);
                data.description = JSON.stringify(data.description);
                data.instancesExpireAt = this.$moment(this.form.expireDate).format('YYYY-MM-DD') + ' ' + this.form.expireTime;
                delete data.expireTime;
                delete data.expireDate;
                accountService.add(data).then(() => {
                    this.$toastr.s(this.$t('pages.content.accountManagement.messages.success.created'));
                    this.$router.push({name: 'ContentAccountManagementList'});
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error);
                    this.loading = false;
                });
            }
        }
    }
};
</script>

<style>
.table-text-wrap {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
